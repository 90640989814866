import React, { PureComponent, ReactNode, SFC } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Slider, { Settings } from 'react-slick'

import Layout from '../components/layout'
import { FirstSectionWrapper, FirstSection, FirstSectionTextWrapper, FirstSectionText } from './index'
import Section from '../components/section/section'
import { WindowLocation } from '@reach/router'

import theme from '../theme'

interface Props {
  location: WindowLocation,
  children: ReactNode[]
}

const BannerContainer = styled.div`
    width: 100vw;
    display: flex;
    margin-top: 50px;
`

const BannerLeft = styled.div`
    transform: perspective(30em) skewY(30deg) translateY(2px);
    background: rgb(245, 162, 27);
    width: 100px;
    box-shadow: 2px 2px 10px -4px black;
`

const BannerCenter = styled.div`
    background: ${theme.palette.primary.main};
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(28px);
    width: 100%;
    height: 60px;
    box-shadow: 2px 2px 10px -5px black;
`

const BannerRight = styled.div`
    background: rgb(245, 162, 27);
    width: 100px;
    height: 60px;
    transform: perspective(30em) skewY(-30deg) translateY(2px);
    box-shadow: 2px 2px 10px -4px black;
`

const BannerText = styled.p`
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    margin: 0;
`

const SliderImage = styled.img`
    margin: 0;
    box-shadow: 0px 4px 10px -2px black;
    border-radius: 4px;
    max-height: calc(100vw * 9/16);
    max-width: 90%;
`

const SlickSlider = styled(Slider)`
    .slick-prev:before,
    .slick-next:before {
        color: ${theme.palette.secondary.dark};
    }

    .slick-slide {
        padding: 20px;
    }
`

const ListTitle = styled.p`
    font-size: 22px;
    font-weight: bold;
`

const ListItemElement = styled.li``

const EquipmentWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
`

type ListItems = {
    items: Array<{
        name: string,
        count: number
    }>
}

const lightingAndGripEquipment: ListItems = {
    items: [{
        name: 'ARRI 1K',
        count: 1
    }, {
        name: 'ARRI 2K',
        count: 2
    }, {
        name: 'ARRI 150w',
        count: 2
    }, {
        name: 'ARRI 300w',
        count: 3
    }, {
        name: 'ARRI 650w',
        count: 2
    }, {
        name: 'ARRI 750w',
        count: 1
    }, {
        name: 'ARRI ARRILITE 1000w',
        count: 1
    }, {
        name: 'Source 4S',
        count: 6
    }, {
        name: 'Source 4 Lenses',
        count: 5
    }, {
        name: 'Neewer On Camera Led',
        count: 2
    }, {
        name: 'Rosco Ring Light',
        count: 1
    }, {
        name: 'Chauvet Strobes',
        count: 4
    }, {
        name: 'Nine-Light Molefay',
        count: 2
    }]
}

const kinoFloEquipment: ListItems = {
    items: [{
        name: 'Kino Flo 4 Bank',
        count: 4
    }, {
        name: 'Kino Flo 2 Bank',
        count: 2
    }, {
        name: 'Kino Flo Ballast',
        count: 4
    }, {
        name: 'Kino Flo Dmx Ballast',
        count: 2
    }, {
        name: 'Kino Mini-Flo Ballasts',
        count: 2
    }, {
        name: 'Kino Mini-Flo Ac Adapters',
        count: 2
    }, {
        name: 'Kino Flo Single Head Cable',
        count: 15
    }, {
        name: 'Kino Flo 4 Way Head Cable Splitter',
        count: 4
    }, {
        name: 'Kino Flo Single Lamp Harness (2’)',
        count: 10
    }, {
        name: 'Kino Flo Single Lamp Harness (4’)',
        count: 10
    }]
}

const miscEquipment: ListItems = {
    items: [{
        name: 'Chauvet Fogger',
        count: 1
    }, {
        name: 'Antari Fog Machine: Hz-500 (Hazer)',
        count: 1
    }, {
        name: '4’X4’ Silk',
        count: 2
    }, {
        name: '5 In 1 Reflector',
        count: 2
    }, {
        name: '15’X30’ Green Screen',
        count: 1
    }, {
        name: 'Doorway Dolly',
        count: 1
    }, {
        name: 'Skate Wheels',
        count: 4
    }, {
        name: '6’ Straight Track',
        count: 6
    }, {
        name: '6’ Curved Track',
        count: 8
    }, {
        name: 'Stedicam Harness',
        count: 1
    }, {
        name: 'Stedicam Rig',
        count: 1
    }, {
        name: 'Canon Speedlite 430Ex Ii',
        count: 1
    }, {
        name: 'Stedicam Rig',
        count: 6
    }]
}

const redDragonPackageEquipment: ListItems = {
    items: [{
        name: 'Red Dragon 6K Camera',
        count: 1
    }, {
        name: 'Red Dsmc Canon Ef Mount',
        count: 1
    }, {
        name: 'Red Dsmc S35 Ti Pl Mount',
        count: 1
    }, {
        name: 'Red Touch 5.0” Lcd',
        count: 1
    }, {
        name: 'Redmote',
        count: 1
    }, {
        name: 'Matte Box W/ Full Cage',
        count: 1
    }, {
        name: 'Matte Box Flaps',
        count: 1
    }, {
        name: 'Dsmc Universal Mount (19Mm)',
        count: 1
    }, {
        name: 'Matte Box Rod Support',
        count: 2
    }, {
        name: 'Dsmc Side Handle',
        count: 1
    }, {
        name: 'Dsmc Tactical Ribcage Pack',
        count: 1
    }, {
        name: 'Red Armor - Epic-X Brain',
        count: 1
    }, {
        name: 'The Clutch Shoulder Rig',
        count: 1
    }, {
        name: 'Dsmc Tactical Bottom Z Adaptor (Right)',
        count: 1
    }, {
        name: 'Redmag 128Gb Memory Cards',
        count: 8
    }, {
        name: 'Red Station Redmag 1.8”',
        count: 2
    }, {
        name: 'Pro I/O Module',
        count: 1
    }, {
        name: 'Quickplate Module',
        count: 1
    }, {
        name: 'Adapter Module W/ Red Pro Evf Pack',
        count: 1
    }, {
        name: 'Pro Battery Module (Quad)',
        count: 1
    }, {
        name: 'Ac Power Adapter - 150W (Dsmc)',
        count: 1
    }, {
        name: 'Redvolt Charger (Quad)',
        count: 1
    }, {
        name: 'Redvolt Package (Batteries)',
        count: 4
    }, {
        name: 'Redvolt Xl Battery',
        count: 4
    }, {
        name: 'Red Bricks 140Wh',
        count: 3
    }, {
        name: 'Red Brick Charger',
        count: 1
    }, {
        name: 'Epic Pelican Case 1510',
        count: 1
    }]
}

const audioEquipment: ListItems = {
    items: [{
        name: 'AKG C124 MIC',
        count: 1
    }, {
        name: 'RODE MIC',
        count: 2
    }, {
        name: 'RE50B MIC',
        count: 1
    }, {
        name: 'ECM-77B SONY CONDENSER MIC',
        count: 1
    }, {
        name: 'Senal ENG-18RL Omnidirectional RODE 22CM SOFTIE 19/20',
        count: 1
    }, {
        name: 'Rode NTG3 Precision RF-Biased Shotgun Microphone',
        count: 1
    }, {
        name: 'K-Tek KE-110CC Avalon Series Aluminum Boompole',
        count: 1
    }, {
        name: 'Sony URXP03 Integrated Digital Portable Wireless Receiver',
        count: 2
    }, {
        name: 'Sony UTX-B03 Integrated Digital Wireless Bodypack',
        count: 2
    }, {
        name: 'Sony UTX-P03 Digital Wireless Plug-On Transmitter',
        count: 1
    }, {
        name: 'Sony Lavalier Microphone',
        count: 2
    }]
}

const lensesEquipment: ListItems = {
    items: [{
        name: 'Cooke 32mm T2.3 Anamorphic',
        count: 1
    }, {
        name: 'Cooke 50mm T2.3 Anamorphic',
        count: 1
    }, {
        name: 'Cooke 100mm T2.3 Anamorphic',
        count: 1
    }, {
        name: 'Cooke 135mm T2.3 Anamorphic',
        count: 1
    }, {
        name: 'Canon EF-S 10-22mm f/3.5-4.5 USM',
        count: 1
    }, {
        name: 'Canon EF 17-40mm f/4L USM',
        count: 1
    }, {
        name: 'Canon EF-S 17-55mm f/2.8 IS USM',
        count: 1
    }, {
        name: 'Canon EF 24-70mm f/2.8L USM',
        count: 1
    }, {
        name: 'Canon EF 28-135mm f/3.5-5.6 IS USM',
        count: 1
    }, {
        name: 'Canon EF 70-200mm f/2.8L IS USM',
        count: 1
    }, {
        name: 'Canon EF 8-15mm f/4L Fisheye USM',
        count: 1
    }, {
        name: 'Canon 85mm',
        count: 1
    }, {
        name: 'Canon 45mm TILT SHIFT',
        count: 1
    }]
}

export default class Equipment extends PureComponent<Props, { isMobile: boolean}> {
    state = {
        isMobile: false
    }

    componentDidMount() {
        this.setState({
            isMobile: typeof window !== 'undefined' && window.innerWidth <= 768
        })
    }
    
    render() {
        const sliderSettings: Settings = {
            dots: false,
            infinite: true,
            autoplaySpeed: 3000,
            autoplay: true,
            slidesToShow: this.state.isMobile ? 1 : 3,
            slidesToScroll: 1
        }

        return(
        <Layout location={this.props.location}>
            <Helmet
            link={[
                { 'href': 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css', 'rel':'stylesheet', 'charset': 'UTF-8' },
                { 'href': 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css', 'rel':'stylesheet', 'charset': 'UTF-8' }
            ]} />
            <FirstSectionWrapper>
            <FirstSection src={require('../images/equipment/greenCurtain.jpg')} alt="green curtain"/>
            <FirstSectionTextWrapper top="unset" bottom={40} left={40}>
                <FirstSectionText>EQUIPMENT</FirstSectionText>
            </FirstSectionTextWrapper>
            </FirstSectionWrapper>
            <Section 
                width="100%" 
                margin="0" 
                flexDirection="column" 
                background="white">
                <BannerContainer>
                    <BannerLeft/>
                    <BannerCenter>
                        <BannerText>Cameras</BannerText>
                    </BannerCenter>
                    <BannerRight />
                </BannerContainer>
                <Section 
                    flex={1}
                    padding="0 0 40px 0"
                    width="100%"
                    margin="0"
                    background="white">
                    <div style={{
                        width: '90vw',
                        margin: '40px auto 0 auto'
                    }}>
                        <SlickSlider {...sliderSettings}>
                            <SliderImage src={require('../images/equipment/camera/camera1.jpg')}/>
                            <SliderImage src={require('../images/equipment/camera/camera2.jpg')}/>
                            <SliderImage src={require('../images/equipment/camera/camera3.jpg')}/>
                            <SliderImage src={require('../images/equipment/camera/camera4.jpg')}/>
                            <SliderImage src={require('../images/equipment/camera/camera5.jpg')}/>
                            <SliderImage src={require('../images/equipment/camera/camera6.jpg')}/>
                            <SliderImage src={require('../images/equipment/camera/camera7.jpg')}/>
                            <SliderImage src={require('../images/equipment/camera/camera8.jpg')}/>
                            <SliderImage src={require('../images/equipment/camera/camera9.jpg')}/>
                            <SliderImage src={require('../images/equipment/camera/camera10.jpg')}/>
                        </SlickSlider>
                    </div>
                </Section>
            </Section>
            <Section 
                width="100%" 
                padding="0 0 40px 0"
                margin="0" 
                flexDirection="column" 
                background="whitesmoke">
                <BannerContainer>
                    <BannerLeft/>
                    <BannerCenter>
                        <BannerText>Lighting</BannerText>
                    </BannerCenter>
                    <BannerRight />
                </BannerContainer>
                <Section 
                    flex={1}
                    padding="20px"
                    width="100%"
                    margin="0"
                    background="whitesmoke">
                    <div style={{
                        width: '90vw',
                        margin: '40px auto 0 auto'
                    }}>
                        <SlickSlider {...sliderSettings}>
                            <SliderImage src={require('../images/equipment/lights/lights1.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights2.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights3.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights4.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights5.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights6.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights7.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights8.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights9.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights10.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights11.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights12.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights13.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights14.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights15.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights16.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights17.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights18.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights19.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights20.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights21.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights22.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights23.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights24.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights25.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights26.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights27.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights28.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights29.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights30.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights31.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights32.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights33.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights34.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights35.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights36.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights37.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights38.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights39.jpg')}/>
                            <SliderImage src={require('../images/equipment/lights/lights40.jpg')}/>
                        </SlickSlider>
                    </div>
                </Section>
            </Section>
            <Section 
                width="100%" 
                margin="0"
                padding="0 0 40px 0" 
                flexDirection="column" 
                background="white">
                <BannerContainer>
                    <BannerLeft/>
                    <BannerCenter>
                        <BannerText>Stage Sets</BannerText>
                    </BannerCenter>
                    <BannerRight />
                </BannerContainer>
                <Section 
                    flex={1}
                    padding="20px"
                    width="100%"
                    margin="0"
                    background="white">
                    <div style={{
                        width: '90vw',
                        margin: '40px auto 0 auto'
                    }}>
                        <SlickSlider {...sliderSettings}>
                            <SliderImage src={require('../images/stageSets/stageSet1.jpg')}/>
                            <SliderImage src={require('../images/stageSets/stageSet2.jpg')}/>
                            <SliderImage src={require('../images/stageSets/stageSet3.jpg')}/>
                            <SliderImage src={require('../images/stageSets/stageSet6.jpg')}/>
                            <SliderImage src={require('../images/stageSets/stageSet7.jpg')}/>
                            <SliderImage src={require('../images/stageSets/stageSet8.jpg')}/>
                            <SliderImage src={require('../images/stageSets/stageSet9.jpg')}/>
                            <SliderImage src={require('../images/stageSets/stageSet10.jpg')}/>
                            <SliderImage src={require('../images/stageSets/stageSet11.jpg')}/>
                            <SliderImage src={require('../images/stageSets/stageSet12.jpg')}/>
                        </SlickSlider>
                    </div>
                </Section>
            </Section>
            <Section 
                width="100%" 
                margin="0"
                padding="0 0 40px 0" 
                flexDirection="column" 
                background="whitesmoke">
                <BannerContainer>
                    <BannerLeft/>
                    <BannerCenter>
                        <BannerText>Miscellaneous</BannerText>
                    </BannerCenter>
                    <BannerRight />
                </BannerContainer>
                <Section 
                    flex={1}
                    padding="20px"
                    width="100%"
                    margin="0"
                    background="whitesmoke">
                    <div style={{
                        width: '90vw',
                        margin: '40px auto 0 auto'
                    }}>
                        <SlickSlider {...sliderSettings}>
                            <SliderImage src={require('../images/equipment/equipment1.jpg')}/>
                            <SliderImage src={require('../images/equipment/equipment2.jpg')}/>
                            <SliderImage src={require('../images/equipment/dolly/dolly1.jpg')}/>
                            <SliderImage src={require('../images/equipment/dolly/dolly2.jpg')}/>
                            <SliderImage src={require('../images/equipment/dolly/dolly3.jpg')}/>
                            <SliderImage src={require('../images/equipment/dolly/dolly4.jpg')}/>
                            <SliderImage src={require('../images/equipment/dolly/dolly5.jpg')}/>
                        </SlickSlider>
                    </div>
                </Section>
            </Section>
            <Section
                width="100%"
                margin="0"
                padding="40px"
                flexDirection="column">
                <div style={{
                    width: '90vw',
                    margin: '40px auto 0 auto',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                }}>
                    <EquipmentWrap>
                        <ListTitle>Lighting And Grip Equipment</ListTitle>
                        <ul>
                            {<ListItem equipment={lightingAndGripEquipment}/>}
                        </ul>
                    </EquipmentWrap>
                    <EquipmentWrap>
                        <ListTitle>Kino Flo</ListTitle>
                        <ul>
                            {<ListItem equipment={kinoFloEquipment}/>}
                        </ul>
                    </EquipmentWrap>
                    <EquipmentWrap>
                        <ListTitle>Audio</ListTitle>
                        <ul>
                            {<ListItem equipment={audioEquipment}/>}
                        </ul>
                    </EquipmentWrap>
                    <EquipmentWrap>
                        <ListTitle>Lenses</ListTitle>
                        <ul>
                            {<ListItem equipment={lensesEquipment}/>}
                        </ul>
                    </EquipmentWrap>
                    <EquipmentWrap>
                        <ListTitle>Miscellaneous</ListTitle>
                        <ul>
                            {<ListItem equipment={miscEquipment}/>}
                        </ul>
                    </EquipmentWrap>
                    <EquipmentWrap>
                        <ListTitle>Red Dragon Package</ListTitle>
                        <ul>
                            {<ListItem equipment={redDragonPackageEquipment}/>}
                        </ul>
                    </EquipmentWrap>
                </div>
            </Section>
        </Layout>
        )
    }
}

const ListItem: SFC<{equipment: ListItems}> = ({ equipment }) => (
    equipment.items.map(item => <ListItemElement key={`${item.name}_${item.count}`}>{item.name} - {item.count}</ListItemElement>)
)