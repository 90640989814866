import React, { PureComponent, SyntheticEvent, ImgHTMLAttributes, ReactNode } from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Slider from 'react-slick'

import Modal from '../components/modal'
import Section, {SubSection, Header, SubHeader, Divider} from '../components/section/section'
import { WindowLocation } from '@reach/router'
import ImageCarousel, { DisplayItem } from '../components/imageCarousel'

import theme from '../theme'

const ImageGrid = styled.div`
  display: grid;
  grid-template-areas: 'a1 a2 a2' 'b1 b1 b2' 'c1 c2 c2';
  grid-gap: 1rem;

  @media(max-width: 600px) {
    grid-template-areas: 'a1' 'a2' 'b1' 'b2' 'c1' 'c2';
  }
`

export const FirstSectionWrapper = styled.div`
  max-height: calc(100vw * 9/16);
  margin-top: -100px;
  box-shadow: 0px 2px 10px 0px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: ${theme.palette.primary.main};

  @media(max-width: 768px) {
    margin-top: -112px;
  }
`

export const FirstSection = styled.img`
  width: 100vw;
  max-height: inherit;
`

export const FirstSectionTextWrapper = styled.div<{top?: number | 'unset', bottom?: number | 'unset', left?: number | 'unset'}>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: ${props => props.top ? props.top + 'px' : '120px'};
  left: ${props => props.left ? props.left + 'px' : '40px'};
  bottom: ${props => props.bottom ? props.bottom + 'px' : 'unset'};

  @media(max-width: 768px) {
    top: 66px;
    left: 20px
  }
`

export const FirstSectionText = styled.p<{top: number}>`
  font-size: 3.5rem;
  font-weight: 500;
  color: white;
  left: 20px;
  font-family: roboto;
  margin: 0;

  @media(max-width: 768px) {
    font-size: 1.5rem;
  }

  @media(max-width: 400px) {
    font-size: 1.25rem
  }
`

export const HeaderBar = styled.div`
  width: 100%;
  background-color: #313131;
  position: relative;
  z-index: 0;
`

export const HeaderBarText = styled.p`
  font-size: 1.5rem;
  color: white;
  text-align: center;
  margin: 0;
  padding: 10px;

  @media(max-width: 520px) {
    font-size: 1.25rem;
  }
`

export const HeaderBarBottom = styled.div`
  height: 20px;
  background-color: #232323;
`

const SpaceSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
`

const SpaceSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;

  :last-of-type {
    justify-content: space-around;

    > div {
      border-radius: 3px;
      width: calc(50vw * 9/16);

      @media(max-width: 1330px) {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }

  @media(max-width: 1330px) {
    width: 100%;
  }
`

const SpaceBlock = styled(Section)`
  border-radius: 3px 3px 0 0;
  padding: 15px;
  margin: 0;
  background: rgba(250,178,41,0.9);
  flex-direction: column;
  text-align: center;
  height: 150px;
  justify-content: center;

  p:first-of-type {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 0;
  }

  @media(max-width: 1330px) {
    height: unset;
  }
`

const SpaceImage = styled.img`
  width: calc(50vw * 9/16);
  max-height: calc(33vw * 9/16);
  border-radius: 0 0 3px 3px;

  @media(max-width: 1330px) {
    width: unset;
    max-height: unset;
  }
`

const GifText = styled.p`
  color: white;
  font-size: 4rem;
  font-weight: bold;

  @media(max-width: 1056px) {
    font-size: 3rem;
  }

  @media(max-width: 768px) {
    font-size: 1.5rem;
  }
`

const Services = styled.div<{two?: boolean}>`
  display: grid;
  grid-template-columns: repeat(${props => props.two ? 2 : 3}, 1fr);
  background-color: ${theme.palette.primary.main};
  grid-gap: 3px;

  > img { 
    margin: 0; 
  }

  @media(max-width: 768px) {
    grid-template-columns: unset;
    grid-template-rows: repeat(${props => props.two ? 2 : 3}, 1fr);
  }
`

const EquipmentInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const EquipmentImagesContainer = styled.div`
  display: flex;
  height: 400px;
  width: 100vw;
  justify-content: center;
  z-index: 9;
  margin-top: 100px;
  transform: translateY(-30px);

  img { 
    margin: 10px;
    height: calc(50vh * 9/16);
    box-shadow:0 3px 8px -1px rgba(0,0,0,0.3), 0 0 20px rgba(0,0,0,0.1) inset;
    border-radius: 2px;
    position: relative;
  }

  @media(max-width: 1340px) {
    flex-direction: row;
    height: 300px;

    img {
      max-height: calc(28vw * 9/16);
    }
  }

  @media(max-width: 820px) {
    width: unset;
    height: auto;
    flex-direction: column;
    

    img {
      margin: 10px auto;
      max-height: calc(100vw * 9/16);
      height: unset;
    }
  }
`

const EquipmentImageOne = styled.img`
  transform: perspective(40em) rotateY(30deg);

  @media(max-width: 820px) { 
    transform: unset;
  }
`

const EquipmentImageTwo = styled.img`
  transform: perspective(40em) rotateY(15deg);

  @media(max-width: 820px) { 
    transform: unset;
  }
`

const EquipmentImageThree = styled.img`
  transform: perspective(40em) rotateY(-15deg);

  @media(max-width: 820px) { 
    transform: unset;
  }
`

const EquipmentImageFour = styled.img`
  transform: perspective(40em) rotateY(-30deg);

  @media(max-width: 820px) { 
    transform: unset;
  }
`

const ViewMoreEquipment = styled(Link)`
  && {
    font-weight: bold;
    width: fit-content;
    color: ${theme.palette.primary.dark};

    :hover {
      color: rgba(0,0,0,0.5);
    }
  }
`

const EquipmentPlatform = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(250, 178, 41, 0.9);
  transform: perspective(28.65em) rotateX(55deg);
  border-radius: 10px 10px 0 0;
  z-index: 0;
  box-shadow: 0px 0px 21px -4px black;

  @media(max-width: 1340px) {
    transform perspective(23.65em) rotateX(55deg)
  }

  @media(max-width: 820px) { 
    height: 0;
  }
`

interface IndexPageProps {
  location: WindowLocation,
  children: ReactNode[]
}

interface IndexPageState {
  isMobile: boolean,
  toggledImage: string | undefined
}
export default class Index extends PureComponent<IndexPageProps, IndexPageState> {
  constructor(props: IndexPageProps) {
    super(props)

    this.toggleImage = this.toggleImage.bind(this)

    this.state = {
      isMobile: false,
      toggledImage: undefined
    }
  }

  componentDidMount() {
    this.setState({
      isMobile: typeof window !== 'undefined' && window.innerWidth <= 768
    })
  }

  toggleImage = (event: SyntheticEvent<ImgHTMLAttributes<HTMLImageElement>>) : void => {
    this.setState({
      toggledImage: event.currentTarget ? event.currentTarget.src : undefined
    })
  }

  render() {
    const modalizedImage = this.state.toggledImage ? (
      <Modal handleClose={this.toggleImage}>
        <img 
          src={this.state.toggledImage} 
          alt="Toggled"
          style={{
            margin: '0',
            maxHeight: '90vh',
            maxWidth: '99vw', 
            objectFit: 'contain',
            padding: '40px'
          }}/>
      </Modal>
    ) : null

    const sliderSettings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: this.state.isMobile ? 1 : 3,
      slidesToScroll: 1
    }

    return (
      <Layout location={this.props.location}>
        <FirstSectionWrapper>
          <FirstSection src={require('../images/aboutUs.jpg')} alt="mic"/>
          <FirstSectionTextWrapper>
            <FirstSectionText top={120}>THE</FirstSectionText>
            <FirstSectionText top={150}>SOUNDSTAGE</FirstSectionText>
            <FirstSectionText top={180}>AT</FirstSectionText>
            <FirstSectionText top={210}>STRANGE LAND</FirstSectionText>
          </FirstSectionTextWrapper>
        </FirstSectionWrapper>
        <Section
          width="100%"
          margin="0"
          background="whitesmoke"
          flexDirection="column">
          <p style={{ 
            fontSize: '1.5rem',
            margin: '20px 0 0 20px'
          }}>The Space</p>
          <Section 
            margin="20px"
            background="whitesmoke"
            flexWrap="wrap">
            <Section 
              width="100%"
              flexDirection="column">
              <p>
                The Soundstage at Strange Land features
                ample space for projects of any size, with
                enough room to setup multiple station
                shoots. With plenty of usable workspace
                extending away from the cycloramic wall,
                The Soundstage can accommodate
                a variety of projects.
              </p>
              <p>Some of the many features include...</p>
            </Section>
            <Section 
              justifyContent="space-around"
              flexWrap="wrap" 
              margin="0 auto">
              <SpaceSection>
                <SpaceBlock>
                  <p>Cyclorama Wall</p>
                  <p>40ft W x 20ft H x 17ft D</p>
                  <p>20ft Floor to Grid</p>
                </SpaceBlock>
                <SpaceImage src={require('../images/equipment/cyclorama2.jpg')}/>
              </SpaceSection>
              <SpaceSection>
                <SpaceBlock>
                  <p>Green Screen Curtain</p>
                  <p>40ft W x 20ft H</p>
                </SpaceBlock>
                <SpaceImage src={require('../images/equipment/greenCurtain.jpg')}/>
              </SpaceSection>
              <SpaceSection>
                <SpaceBlock>
                  <p>Black Void Curtain</p>
                  <p>40ft W x 20ft H</p>
                </SpaceBlock>
                <SpaceImage src={require('../images/equipment/blackCurtain.jpg')}/>
              </SpaceSection>
              <SpaceSection>
                <SpaceBlock>
                  <p>Stage</p>
                </SpaceBlock>
                <SpaceImage src={require('../images/equipment/stage.jpg')}/>
              </SpaceSection>
              <SpaceSection>
                <SpaceBlock>
                  <p>Two Loading Docks</p>
                  <p>9ft W x 8ft 2in H</p>
                </SpaceBlock>
                <SpaceImage src={require('../images/garageDoorsB.jpg')}/>
              </SpaceSection>
              <SpaceSection>
                <SpaceBlock>
                  <p>1 Drive-In Bay Door</p>
                  <p>14ft W x 14ft H</p>
                </SpaceBlock>
                <SpaceBlock>
                  <p>Power Sources</p>
                  <p>200 Amp 3 Phase (x2)</p>
                  <p>400 Amp 3 Phase (x2)</p>
                </SpaceBlock>
              </SpaceSection>
            </Section>
          </Section>
        </Section>
        {/* <HeaderBar>
          <HeaderBarText>Music Videos</HeaderBarText>
          <HeaderBarBottom />
        </HeaderBar>
        <Section width="100%" margin="0">
          <div style={{
            width: '100%',
            height: 0,
            paddingBottom: '56%',
            position: 'relative',
            background: 'black'
          }}>
            <div style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              pointerEvents: 'none'
            }}>
              <div style={{
                position: 'absolute',
                top: 20,
                left: 20,
                background: 'rgba(0,0,0,0.5)',
                zIndex: 9999,
                borderRadius: '4px',
                padding: '20px'
              }}>
                <GifText>Mackenzie Nicole's</GifText>
                <GifText>Preview</GifText>
              </div>
              <iframe src="https://giphy.com/embed/fnscGJjwEKGTsFi2ZH" width="100%" height="100%" style={{ position: 'absolute' }} frameBorder="0" class="giphy-embed" allowFullScreen={true} />
            </div>
          </div>
        </Section> */}
        <Section 
          margin="20px" 
          width="100%" 
          flexDirection="column"
          style={{ position: 'relative' }}>
          <SubSection display="flex" flexWrap="wrap" padding="20px">
            <EquipmentInfo>
              <div>
                <p style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Equipment</p>
                <p>Check out some of the in house equipment currently available!</p>
              </div>
              <ViewMoreEquipment to="/equipment">View More</ViewMoreEquipment>
            </EquipmentInfo>
          </SubSection>
          <EquipmentPlatform />
          <EquipmentImagesContainer>
            <EquipmentImageOne src={require('../images/equipment/camera/camera17.jpg')} />
            <EquipmentImageTwo src={require('../images/equipment/camera/camera3.jpg')} />
            <EquipmentImageThree src={require('../images/equipment/camera/camera4.jpg')} />
            <EquipmentImageFour src={require('../images/equipment/camera/camera5.jpg')} />
          </EquipmentImagesContainer>
        </Section>
        <Section 
          background={theme.palette.primary.main} 
          width="100vw" 
          margin="0" 
          flexDirection="column"
          alignItems="center"
          zIndex={2}
          paddingBottom="50px">
          <p style={{ fontWeight: 'bold', fontSize: '1rem', marginTop: '20px'  }}>Some of the brands we use</p>
          <div style={{ width: '90vw', textAlign: 'center', padding: '20px 20px 50px 20px' }}>
            <Slider {...sliderSettings}>
              <DisplayItem imageSrc={require('../images/brands/shotOnRedLogo.png')} text="Red"/>
              <DisplayItem imageSrc={require('../images/brands/teradek.png')} text="Teradek"/>
              <DisplayItem imageSrc={require('../images/brands/arri.png')} text="ARRI"/>
              <DisplayItem imageSrc={require('../images/brands/roninDji.png')} text="RONIN DJI"/>
              <DisplayItem imageSrc={require('../images/brands/cooke.png')} text="Cooke"/>
              <DisplayItem imageSrc={require('../images/brands/kinoflo.png')} text="KINO FLO"/>
            </Slider>
          </div>
        </Section>
        <HeaderBar>
          <HeaderBarText>Production Set Builds</HeaderBarText>
          <HeaderBarBottom />
        </HeaderBar>
        <Services two={true}>            
          <img src={require('../images/stageSets/stageSet1.jpg')}/>
          <img src={require('../images/stageSets/stageSet2.jpg')} />
        </Services>
        <HeaderBar>
          <HeaderBarText>Performance Areas</HeaderBarText>
          <HeaderBarBottom />
        </HeaderBar>
        <Services>            
          <img src={require('../images/performances/performance2.jpg')}/>
          <img src={require('../images/performances/performance5.jpg')}/>
          <img src={require('../images/performances/performance8.jpg')} />
        </Services>
        {modalizedImage}
      </Layout>
    )
  }
}